export default {
    data() {
        return {
            headers: [],
            gather: {

            },
            rightConfig: [],
            leftConfig: [],
            ongather: {

            },
            listDeploy: {

            }
        }
    },
    watch: {
        listData: {
            handler(current) {
                this.gather.listData = current;
            }
        },
        // listDeploy: {
        //     handler(current) {
        //         this.gather.listDeploy = current;
        //     }
        // }
    },
    methods: {
        // 初始化绑定数据
        initGether() {
            this.gather = {
                headers: this.headers,
                rightConfig: this.rightConfig,
                leftConfig: this.leftConfig,
                listDeploy: this.listDeploy,
                btnAction: true,
                listData: this.listData,
                loading: this.loading,
                btnActionEvent: this.btnActionEvent
            };
            this.ongather = {
                search: this.search,
                remove: this.remove,
                save: this.save,
                edit: this.edit,
                changePage: this.changePage,
                otherRightOperate: this.otherRightOperate
            }
        },
        // 把想在created中执行的代码放进这个函数里面
        // 此函数会在created()中调用，顺序是列表api调用之前
        async created_extend() {
            
        },
        otherRightOperate() {

        }
    },
    async created() {
        this.initGether();
        await this.created_extend();
        // setTimeout(() => {
        //     console.log(this.gather,this.listData)
        // }, 3000);
    }
}