
export default {
    data() {
        // 当一个对象比如a的堆内存类型的属性赋值给另外一个对象b的属性上时，b对象上的堆内存属性值就从a的原型上
        // 断开，用b的原型替换继承。
        // 所以下面的itemGroups.theme修改的时候，不会影响到listDeploy对象上的属性值
        let itemGroups = {
            theme: []
        };
        // setTimeout(() => {
        //     console.log(this.itemGroups);
        // }, 5000);
        return {
            itemGroups,
            // 套餐主题表单字段
            themeDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "code",
                        connector: {
                            props: {
                                required: true,
                                label: "主题代码",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "description",
                        connector: {
                            props: {
                                required: true,
                                label: "主题名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "status",
                        connector: {
                            props: {
                                required: true,
                                label: "预订状态",
                                items: [
                                    {
                                        desc: '未启动',
                                        code: 0
                                    },
                                    {
                                        desc: '启动',
                                        code: 1
                                    }
                                ]
                            },
                        }
                    }
                ]
            },
            // 套餐大类表单字段
            listDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "code",
                        connector: {
                            props: {
                                required: true,
                                readonly: true,
                                label: "套餐小类代码",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "description",
                        connector: {
                            props: {
                                required: true,
                                label: "套餐小类名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "lowest",
                        connector: {
                            props: {
                                required: true,
                                label: "套餐最低价",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "rulecode",
                        connector: {
                            props: {
                                required: true,
                                label: "业务规则",
                                items: [],
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'showdesc',
                        connector: {
                            props: {
                                label: "显示别名"
                            }
                        }
                    },
                    // {
                    //     cols: 6,
                    //     subassembly: "WSwitches",
                    //     property: 'lsell',
                    //     connector: {
                    //         props: {
                                
                    //         }
                    //     }
                    // },
                ],
            },
            
        }
    }
}
