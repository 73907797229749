const rightCode = {
    "套餐管理": {
        add: 82302,
        load: 82301,
        list: 82300,
        del: 82303,
    },
    "套餐小类": {
        add: 82307,
        load: 82306,
        list: 82305,
        del: 82308,
    },
    "一级栏目": {
        add: 10000,
        load: 10004,
        list: 10003,
        del: 10004,
    },
    "二级栏目": {
        add: 0,
        load: 1,
        list: 2,
        del: 3,
    },
    "三级栏目": {
        add: 0,
        load: 1,
        list: 2,
        del: 3,
    },
    "基础信息": {
        save: 850001
    },
    "内容管理": {
        add: 10007,
        load: 10006,
        list: 10010,
        del: 10004,
    }
}
// rightCode.__proto__.find = function (code) {
//     let result = seeionStorage.getItem('operatePermissionKey');
//     if (result) {
//         return result.includes(code);
//     }
// }
export default rightCode;