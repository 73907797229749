var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-table',_vm._g(_vm._b({ref:"ct",attrs:{"itemScope":['lsell', 'price'],"listDeploy":_vm.listDeploy,"loading":_vm.loading},on:{"beforeOpenAddDialog":_vm.beforeOpenAddDialog},scopedSlots:_vm._u([{key:"lsell",fn:function(ref){
var item = ref.item;
return [_c('w-switches',{attrs:{"value":item.lsell,"hideDetails":true,"dense":true,"label":""},on:{"change":function($event){return _vm.updateStatus(item.sqlid)}}})]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"24","color":"primary"},on:{"click":function($event){return _vm.checkPrice(item.code)}}},[_vm._v("iconfont icon-chakan-fill")])]}}])},'common-table',_vm.gather,false),_vm.ongather)),_c('exhibition-data-box',{attrs:{"dialog":_vm.boxdialog,"title":"价格&库存","width":874,"contentHeight":528},on:{"update:dialog":function($event){_vm.boxdialog=$event}}},[_c('div',[_c('commonTable',{attrs:{"headers":_vm.headers1,"listData":_vm.resources_price,"itemScope":_vm.itemScope},scopedSlots:_vm._u([{key:"room",fn:function(ref){
var item = ref.item;
return [(item.room)?[(item.room.length === 1)?[_c('div',[_vm._v(" "+_vm._s(item.room[0].code + " : " + item.room[0].price)+" ")])]:[_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.room.length > -1 ? item.room[0].code + " : " + item.room[0].price : "")+" ... ")])]}}],null,true)},[_c('div',_vm._l((item.room),function(room,index){return _c('p',{key:index,staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(((room.code) + " : " + (room.price)))+" ")])}),0)])]]:_vm._e()]}},{key:"ticket",fn:function(ref){
var item = ref.item;
return [(item.ticket)?[(item.ticket.length === 1)?[_c('div',[_vm._v(" "+_vm._s(item.ticket[0].code + " : " + item.ticket[0].price)+" ")])]:[_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.ticket.length > -1 ? item.ticket[0].code + " : " + item.ticket[0].price : "")+" ... ")])]}}],null,true)},[_c('div',_vm._l((item.ticket),function(ticket,index){return _c('p',{key:index,staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(((ticket.code) + " : " + (ticket.price)))+" ")])}),0)])]]:_vm._e()]}},{key:"cater",fn:function(ref){
var item = ref.item;
return [(item.cater)?[(item.cater.length === 1)?[_c('div',[_vm._v(" "+_vm._s(item.cater[0].code + " : " + item.cater[0].price)+" ")])]:[_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.cater.length > -1 ? item.cater[0].code + " : " + item.cater[0].price : "")+" ... ")])]}}],null,true)},[_c('div',_vm._l((item.cater),function(cater,index){return _c('p',{key:index,staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(((cater.code) + " : " + (cater.price)))+" ")])}),0)])]]:_vm._e()]}},{key:"other",fn:function(ref){
var item = ref.item;
return [(item.other)?[(item.other.length === 1)?[_c('div',[_vm._v(" "+_vm._s(item.other[0].code + " : " + item.other[0].price)+" ")])]:[_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.other.length > -1 ? item.other[0].code + " : " + item.other[0].price : "")+" ... ")])]}}],null,true)},[_c('div',_vm._l((item.other),function(other,index){return _c('p',{key:index,staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(((other.code) + " : " + (other.price)))+" ")])}),0)])]]:_vm._e()]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }