<template>
  <div>
    <common-table
      v-bind="gather"
      ref="ct"
      v-on="ongather"
      @beforeOpenAddDialog="beforeOpenAddDialog"
      :itemScope="['lsell', 'price']"
      :listDeploy="listDeploy"
      :loading="loading"
    >
      <template v-slot:lsell="{ item }">
        <!-- {{item}} -->
        <w-switches
          :value="item.lsell"
          @change="updateStatus(item.sqlid)"
          :hideDetails="true"
          :dense="true"
          label=""
        ></w-switches>
      </template>
      <template v-slot:price="{ item }">
        <v-icon size="24" @click="checkPrice(item.code)" color="primary"
          >iconfont icon-chakan-fill</v-icon
        >
      </template>
    </common-table>
    <!-- <dialogTableBox
      title="价格&库存"
      :headers="headers1"
      ref="subject"
      outer
    >
    </dialogTableBox> -->
    <exhibition-data-box
      :dialog.sync="boxdialog"
      title="价格&库存"
      :width="874"
      :contentHeight="528"
    >
      <div>
        <commonTable
          :headers="headers1"
          :listData="resources_price"
          :itemScope="itemScope"
        >
          <!-- <template v-for="(name) in itemScope">
          <div :key="name">
            <template v-slot:ticket="{item}">
            {{name}}
          </template>
          </div>
        </template> -->

          <template v-slot:room="{ item }">
            <template v-if="item.room">
              <template v-if="item.room.length === 1">
                <div>
                  {{ item.room[0].code + " : " + item.room[0].price }}
                </div>
              </template>
              <template v-else>
                <v-tooltip bottom nudge-left="20" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{
                        item.room.length > -1
                          ? item.room[0].code + " : " + item.room[0].price
                          : ""
                      }}
                      ...
                    </div>
                  </template>
                  <div>
                    <p
                      v-for="(room, index) in item.room"
                      :key="index"
                      style="margin-bottom: 0px"
                    >
                      {{ `${room.code} : ${room.price}` }}
                    </p>
                  </div>
                </v-tooltip>
              </template>
            </template>
          </template>
          <!-- ticket -->
          <template v-slot:ticket="{ item }">
            <template v-if="item.ticket">
              <template v-if="item.ticket.length === 1">
                <div>
                  {{ item.ticket[0].code + " : " + item.ticket[0].price }}
                </div>
              </template>
              <template v-else>
                <v-tooltip bottom nudge-left="20" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{
                        item.ticket.length > -1
                          ? item.ticket[0].code + " : " + item.ticket[0].price
                          : ""
                      }}
                      ...
                    </div>
                  </template>
                  <div>
                    <p
                      v-for="(ticket, index) in item.ticket"
                      :key="index"
                      style="margin-bottom: 0px"
                    >
                      {{ `${ticket.code} : ${ticket.price}` }}
                    </p>
                  </div>
                </v-tooltip>
              </template>
            </template>
          </template>
          <!-- cater -->
          <template v-slot:cater="{ item }">
            <template v-if="item.cater">
              <template v-if="item.cater.length === 1">
                <div>
                  {{ item.cater[0].code + " : " + item.cater[0].price }}
                </div>
              </template>
              <template v-else>
                <v-tooltip bottom nudge-left="20" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{
                        item.cater.length > -1
                          ? item.cater[0].code + " : " + item.cater[0].price
                          : ""
                      }}
                      ...
                    </div>
                  </template>
                  <div>
                    <p
                      v-for="(cater, index) in item.cater"
                      :key="index"
                      style="margin-bottom: 0px"
                    >
                      {{ `${cater.code} : ${cater.price}` }}
                    </p>
                  </div>
                </v-tooltip>
              </template>
            </template>
          </template>
          <!-- other -->
          <template v-slot:other="{ item }">
            <template v-if="item.other">
              <template v-if="item.other.length === 1">
                <div>
                  {{ item.other[0].code + " : " + item.other[0].price }}
                </div>
              </template>
              <template v-else>
                <v-tooltip bottom nudge-left="20" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{
                        item.other.length > -1
                          ? item.other[0].code + " : " + item.other[0].price
                          : ""
                      }}
                      ...
                    </div>
                  </template>
                  <div>
                    <p
                      v-for="(other, index) in item.other"
                      :key="index"
                      style="margin-bottom: 0px"
                    >
                      {{ `${other.code} : ${other.price}` }}
                    </p>
                  </div>
                </v-tooltip>
              </template>
            </template>
          </template>
        </commonTable>
      </div>
    </exhibition-data-box>
  </div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import commonCurdSubjoin from "@/mixin/commonCurd-subjoin.js";
import api from "@/api/productManage/sub-class.js";
// import dialogTableBox from "@/components/dialog-table-box/dialog-table-box.vue";
import commondata from "@/api/common-data.js";
import form from "./sub-class-form.js";
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box.vue";
import rightCode from "@/constant/rightCode.js";
export default {
  mixins: [commonCurd, commonCurdSubjoin, form],
  components: {
    // dialogTableBox,
    exhibitionDataBox,
  },
  data() {
    return {
      itemScope: ["room", "ticket", "cater", "other"],
      resources_price: {},
      boxdialog: false,
      api,
      dialogcommon: false,
      headers: [
        { text: "套餐小类代码", value: "code" },
        { text: "套餐小类名称", value: "description" },
        { text: "显示别名", value: "showdesc" },
        { text: "开始日期", value: "startdate" },
        { text: "结束日期", value: "enddate" },
        { text: "最低价", value: "lowest" },
        { text: "价格&库存", value: "price" },
        { text: "预订状态", value: "lsell" },
        { text: "操作", value: "actions", width: 120 },
      ],
      headers1: [
        { text: "日期", value: "date" },
        { text: "房-价格", value: "room" },
        { text: "票-价格", value: "ticket" },
        { text: "餐-价格", value: "cater" },
        { text: "其他-价格", value: "other" },
        { text: "剩余库存", value: "avl" },
      ],
      commonlistData: {},
      // 定义右侧按钮
      rightConfig: [
        // {
        //   label: "新建",
        //   icon: "zengjia",
        //   event: "add",
        //   rightCode: rightCode["套餐小类"].add,
        // },
      ],
      btnActionEvent: [
        {
          event: "editItem",
          icon: "bianji2-fill",
          color: "primary",
          tooltip: "编辑",
          rightCode: rightCode['套餐小类'].load,
        },
        {
          event: "deleteItem",
          icon: "shanchu-fill",
          color: "var(--v-redness-base)",
          tooltip: "删除",
          rightCode: rightCode['套餐小类'].del,
        },
        // {
        //   event: "phoneView",
        //   icon: "yidongduan",
        //   color: "primary",
        //   tooltip: "移动端",
        // },
        // {
        //   event: "pcView",
        //   icon: "diannaoduan",
        //   color: "primary",
        //   tooltip: "电脑端",
        // },
      ],
      // 表示同步小类代码获取的状态
      groupid: false,
      fixedParams: {
        searchkey: "",
      },
    };
  },

  methods: {
    threePoint(num) {
      return num > 1 && num > -1 ? "..." : "";
    },
    geshi(array) {
      if (condition) {
      }
    },
    // 查看价格
    checkPrice(code) {
      if (code) {
        this.axios
          .post(this.api.load_resources_price, {
            searchkey: code,
          })
          .then((res) => {
            this.resources_price = res.data;
            this.boxdialog = true;
            console.log(res);
          });
      }
    },
    // 设置lsell状态
    setlsell($enevt, item) {
      // console.log($enevt, item)
    },
    subclass(subcode) {},
    // 获取公共列表数据
    commonList() {
      this.axios
        .post(
          commondata.list,
          commondata.listConstruction({
            type: "kittheme",
          })
        )
        .then((res) => {
          this.commonlistData = res.data;
        });
    },
    // 同步套餐小类
    sync() {
      this.axios
        .post(this.select_data, {
          keyname: "productkit",
          params: [
            {
              key: "groupid",
              value: "",
            },
          ],
        })
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            // 赋值表单套餐小类未选择大类的数据
            // this.listDeploy.row[5].connector.props.items = res.data[0].values;
            this.snackbar.success("同步套餐小类成功！");
          }
        });
    },
    async otherRightOperate(event) {
      console.log(event);
      if (event === "commonlist") {
        // await this.commonList();
        this.$refs.subject.dialog = true;
      } else if (event === "sync") {
        this.groupid = true;
        this.sync();
      }
    },
    created_extend() {
      this.gather.pageTitle = "套餐小类";
      // this.gather.listDeploy = this.listDeploy;
      this.fixedParams.searchkey = this.$route.query.code;
      // console.log(this.$route);
    },
    beforeOpenAddDialog() {
      return this.axios
        .post(this.select_data, { keyname: "rules" })
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.listDeploy.row[3].connector.props.items = res.data[0].values;
          }
        });
    },
  },
};
</script>

<style>
</style>
